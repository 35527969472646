// authenticationSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginResponse, TokenInfo } from "../../../components/interfaces/interfaces";

interface AuthenticationState {
  isLoggedIn: boolean;
  isAdmin: boolean;
  username: string | null; // Add username to the state
  tokenInfo:TokenInfo|undefined
}

const initialState: AuthenticationState = {
  isLoggedIn: false,
  username: null,
  isAdmin: false,
  tokenInfo: undefined
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<LoginResponse>) => {
      state.isLoggedIn = true;
      state.username = action.payload.result?.email??null;
      // Add the bearerToken to tokenInfo
      state.tokenInfo = action.payload.result? { ...action.payload.result, bearerToken: `Bearer ${action.payload.result.accessToken}` }: undefined;
      state.isAdmin = action.payload.result?.email.toLowerCase() === "admin@tetherfi.com";
    },
    setLoggedOut: (state) => {
      state.isLoggedIn = false;
      state.username= null;
      state.isAdmin= false;
      state.tokenInfo= undefined;
    },
  },
});

export const { setLoggedIn, setLoggedOut } = authenticationSlice.actions;

export const selectIsLoggedIn = (state: {
  authentication: AuthenticationState;
}) => state.authentication.isLoggedIn;

export const selectUsername = (state: {
  authentication: AuthenticationState;
}) => state.authentication.username;

export const selectIsAdmin = (state: { authentication: AuthenticationState }) =>
  state.authentication.isAdmin;

export const selectTokenInfo = (state: { authentication: AuthenticationState }) =>
  state.authentication.tokenInfo;
  
export default authenticationSlice.reducer;
