import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Container, Row, Col, Form, Button, Image, InputGroup, Card } from 'react-bootstrap';
import { Envelope, Lock } from 'react-bootstrap-icons';
import './Login.styles.css';
import Logo from "../../assets/img/logo-big.svg";
import { Login } from '../../services/userAuthService';
import { setLoggedIn } from '../../store/slices/auth/authentication.slice';
import { useNavigate, Link, NavLink } from 'react-router-dom';

const LoginForm: React.FC = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [validated, setValidated] = useState(false);

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;
        
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        setLoading(true);
        setError('');

        if (!executeRecaptcha) {
            console.error("reCAPTCHA not loaded");
            setError("reCAPTCHA not loaded. Please try again later.");
            setLoading(false);
            return;
        }

        try {
            const token = await executeRecaptcha("LoginPage");
            const apiResponse = await Login(username, password, token);
      
            if (apiResponse.isSuccess) {                
                dispatch(setLoggedIn(apiResponse));
                navigate("/");
            } else {
                setError('Invalid username or password. Please try again.');
            }
        } catch (error) {
            console.error("Login failed:", error);
            setError('An error occurred during login. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container fluid className="loginform vh-100 d-flex">
          <Row className="w-100">
            <Col md={6} className="bg-light pt-3">
          <NavLink to="/"> {/* Wrap logo with NavLink */}
            <Image src={Logo} alt="Tetherfi Logo" fluid />
          </NavLink>
            </Col>
            <Col md={6} className="d-flex flex-column">
              <div className="d-flex justify-content-end mt-3 me-4">
                <span className="text-muted me-2">Don't have an account?</span>
                <Link to="/signup" className="text-primary fw-bold">
                  Sign up
                </Link>
              </div>
              <Card className="d-flex align-items-center justify-content-center flex-grow-1" border='white'>
                <div className="w-75">
                    <h2 className="fw-bold mb-4">Login</h2>
                    
                    <Form noValidate validated={validated} onSubmit={handleLogin}>
                      <Form.Group className="mb-4" controlId="formUsername">
                        <InputGroup hasValidation>
                          <InputGroup.Text className="bg-light round-r form-filed">
                            <Envelope />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="User Name"
                            className='form-control form-control-lg bg-light font-s-14'
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter a username.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-4" controlId="formPassword">
                        <InputGroup hasValidation>
                          <InputGroup.Text className="bg-light round-r form-filed">
                            <Lock />
                          </InputGroup.Text>
                          <Form.Control 
                            type="password" 
                            placeholder="Password" 
                            className='form-control form-control-lg bg-light font-s-14'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter a password.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      {error && <div className="alert alert-danger">{error}</div>}
                      <Button 
                        className="btn btn-primary2 btn-lg w-100 rounded-pill" 
                        type="submit" 
                        disabled={loading}
                      >
                        {loading ? "Logging in..." : "Login"}
                      </Button>
                    </Form>
                </div>
              </Card>              
            </Col>
          </Row>
        </Container>
    );
};

export default LoginForm;