import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./otp.css";
import Logo from "../../assets/img/logo-big.svg";
import { ConeStriped } from "react-bootstrap-icons";

const OtpForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const [url, setUrl] = useState("");
    const [email, setEmail] = useState("");
  
    useEffect(() => {
      if (location.state && location.state.url) {
        const fullUrl = location.state.url;
        setUrl(fullUrl);
        
        // Parse email from the URL
        const urlParams = new URLSearchParams(fullUrl.split('?')[1]);
        const emailParam = urlParams.get('email');
        if (emailParam) {
          setEmail(decodeURIComponent(emailParam));
        }
      }
      else{
        navigate("/error")
      }
      // Add event listener for messages from iframe
        const handleMessage = (event:MessageEvent) => {

        const eventData = event.data;
        console.log("--------------------",eventData);
        if (eventData.status === 'OTP_VALIDATED') {
            console.log("-------------------->>>>>>>>>>>>>>>>>>>>",eventData); 
          navigate('/signup/registered', { state: {...eventData.data} });
        }
      };
  
      window.addEventListener('message', handleMessage);
  
      // Clean up the event listener
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }, [location]);

  return (
    <Container fluid className="loginform vh-100 d-flex">
      <Row className="w-100">
        <Col md={6} className="bg-light pt-3">
          <Image src={Logo} alt="Tetherfi Logo" fluid />
        </Col>
        <Col md={6} id="otppage" className="col-md-6 d-flex flex-column">
          <div className="d-flex justify-content-end mt-3 me-4">
            <span className="text-muted me-2">Do you have an account?</span>            
            <Link to="/signin" className="text-primary fw-bold">Sign in</Link>
          </div>
          <div className="d-flex align-items-center justify-content-center flex-grow-1">            
            {/* OTP form content will go here */}
            {url && (
              <iframe
                src={`${url}&email=${encodeURIComponent(email)}`}
                title="Embedded Content"
                width="100%"
                height="400px"
                style={{ border: "none" }}
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OtpForm;
