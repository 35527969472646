import React from 'react';
import { Image } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import ProfileIcon from '../../assets/img/profile-1.png';
// import ArrowDropDownIcon from '../../assets/img/arrow_drop_down_circle.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setLoggedOut } from '../../store/slices/auth/authentication.slice';
import './Header.styles.css';
import * as Icons from '../../assets/img/Icons';
const { ProfileDropDown, BillingDropDown, SubscriptionDropDown, Logo } = Icons;

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state: RootState) => state.authentication.isLoggedIn
  );

  const handleLogout = () => {
    dispatch(setLoggedOut()); // Dispatch the logout action
    navigate('/'); // Navigate to the home page
  };

  

  return (
    <header id="header" className="mb-4">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img src={Logo} alt="Logo" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  to="/"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  to="/features"
                >
                  Features
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  to="/pricing"
                >
                  Pricing
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  to="/contact"
                >
                  Contact
                </NavLink>
              </li>
              {!isAuthenticated ? (
                <li className="nav-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "btn sign-in-button active" : "btn sign-in-button"
                    }
                    to="/signin"
                  >
                    Sign in
                  </NavLink>
                </li>
              ) : (
                <Dropdown as="li" className="nav-item">
                  <Dropdown.Toggle as="a" className="d-flex align-items-center text-decoration-none">
                    <img
                      src={ProfileIcon}
                      title="Profile"
                      className="rounded-circle me-2 w50"
                      alt="Profile"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="end" className="dropdown-menu-end shadow-sm">
                    {/* <img
                      src={ArrowDropDownIcon}
                      className="rounded-circle arrow-drop1"
                      alt="Dropdown Arrow"
                    /> */}
                    <Dropdown.Item href="#/profile">
                      <Image src={ProfileDropDown} alt="ProfileDropDown" fluid />
                      <span className='ms-2'>Profile</span>
                    </Dropdown.Item>
                  
                    <Dropdown.Item onClick={()=>navigate('/console/setting')}>
                      <Image src={SubscriptionDropDown} alt="SubscriptionDropDown" fluid />
                      <span className='ms-2'>Subscriptions</span>
                    </Dropdown.Item>
                    
                    <Dropdown.Item href="#/billing">
                      <Image src={BillingDropDown} alt="BillingDropDown" fluid />
                      <span className='ms-2'>Billing</span>
                    </Dropdown.Item>
                    
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item> {/* Logout with onClick */}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </ul>

          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
