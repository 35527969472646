import React, { useEffect, useState } from 'react';
import { Card, Button, Badge, Col, Form, InputGroup, FormControl, Modal } from 'react-bootstrap';
import { BuyAddonPackage } from '../../services/packageService';
import { useSelector } from 'react-redux';
import { selectIsAdmin, selectTokenInfo } from '../../store/slices/auth/authentication.slice';
import { AddonPackage, CurrentPackages } from '../../components/interfaces/interfaces';

interface AddonPackageCardProps {
  addonPackageData: AddonPackage;
  currentPackages: CurrentPackages | null;
  showNotification: (message: string, description: string) => void;
}

const AddonPackageCard: React.FC<AddonPackageCardProps> = ({ addonPackageData, currentPackages, showNotification }) => {
  const tokenInfo = useSelector(selectTokenInfo);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const userRole = useSelector(selectIsAdmin);

  const formatUnits = (unit: any) => {
    switch (unit.UnitType) {
      case 'USERS':
        return `${unit.QuantityPerUnit} Users`;
      case 'STORAGE':
        return `${unit.QuantityPerUnit} ${unit.CurrencyUnit}`;
      case 'SESSIONS':
        return `${parseFloat(unit.QuantityPerUnit).toFixed(0)} Concurrent Sessions`;
      default:
        return '';
    }
  };

  const handleBuyAddon = async (value:any) => {
    if (userRole) {
      showNotification('Error', 'Option disabled for admin users');
      return;
    }
    if (!addonPackageData.Id || !tokenInfo?.bearerToken) {
      showNotification('Error', 'Error buying addon: data mismatch');
      return;
    }

    setLoading(true);
    try {
      const response = await BuyAddonPackage(addonPackageData.Id, value.quantity, tokenInfo.bearerToken);
      setIsActive(response);
      setShowModal(false);
      showNotification('Success', 'Addon purchased successfully.');
    } catch (error) {
      console.error('Error buying addon:', error);
      showNotification('Error', 'Failed to purchase addon.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentPackages && currentPackages.ActiveAddons) {
      setIsActive(
        currentPackages.ActiveAddons.some(
          (activePackage) => activePackage.PackageAddonUnitId === addonPackageData.Id
        )
      );
    }
  }, [currentPackages?.ActiveAddons]);

  return (
    <Col md={4} className="mb-4">
      <Card className={`h-100 shadow-sm ${isActive ? 'border-success' : 'border-light'}`}>
        <Card.Header className="bg-transparent text-center"  style={{ borderBottom: 'none' }} >
          <Badge className="bg-light-purple text-white">{addonPackageData.UnitName}</Badge>
        </Card.Header>
        <Card.Body>
          {/* <h5 className="card-title fw-bold">{addonPackageData.UnitName}</h5> */}
          <p>{formatUnits(addonPackageData)}</p>
          <h3 className="card-price text-purple mb-3">{addonPackageData.PricePerUnit} {addonPackageData.CurrencyUnit}</h3>
          <Button variant="outline-primary" className="w-100" onClick={() => setShowModal(true)} disabled={isActive}>
            {isActive ? 'Added' : 'Add!'}
          </Button>
        </Card.Body>

        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Buy Addon</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="quantity">
                <Form.Label>Quantity:</Form.Label>
                <InputGroup>
                  <FormControl
                    type="number"
                    value={quantity}
                    min={1}
                    onChange={(e) => setQuantity(Number(e.target.value))}
                  />
                </InputGroup>
              </Form.Group>
              <Button variant="primary" className="mt-3 w-100" onClick={handleBuyAddon} disabled={loading}>
                {loading ? 'Processing...' : 'Add'}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </Card>
    </Col>
  );
};

export default AddonPackageCard;
