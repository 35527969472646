import axios  from 'axios';
import { LoginResponse } from '../components/interfaces/interfaces';


const USER_AUTH_BASE_URL = process.env.REACT_APP_USER_AUTH_BASE_URL || 'http://localhost:3005';
const REDIRECTION_BASE_URL =  process.env.REACT_APP_REDIRECTION_BASE_URL || 'http://localhost:3000';

const serviceApi = axios.create({
  baseURL: USER_AUTH_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const registerUser_old = async (email: string, tenant: string, data: any,token:string) => {
  try {
    const result = await serviceApi.post(`/app/auth/register?g-recaptcha-response=${token}`, {
      email,
      tenant,
      data,
      validRedirectUrl:`${REDIRECTION_BASE_URL}/registered`,
      invalidRedirectUrl:`${REDIRECTION_BASE_URL}/error`
    });

    if (result.data.isSuccess) {
        // Check if a redirect URL is provided in the response
        const url = result.data.result || '/error';  
        const redirectUrl = `${window.location.protocol}${url.substring(url.indexOf(":") + 1)}`;
        console.debug(`Rederection url: ${redirectUrl}`);
        // Redirect to the provided URL
        window.location.href = redirectUrl;
        return {isSuccess:true};
      } else {
        
        return {isSuccess:false,data:result.data.result};
      }
  } catch (error:any) {
    console.error('API error : registerUser :', error);
    return {isSuccess:false , data : error.response.data};
    
  }
};

export const registerUser = async (email: string, tenant: string, data: any,token:string) => {
  try {
    const result = await serviceApi.post(`/app/auth/register?g-recaptcha-response=${token}`, {
      email,
      tenant,
      data,
      validRedirectUrl:`${REDIRECTION_BASE_URL}/registered`,
      invalidRedirectUrl:`${REDIRECTION_BASE_URL}/error`
    });

    if (result.data.isSuccess) {
        // Check if a redirect URL is provided in the response
        const url = result.data.result || '/error';  
        const redirectUrl = `${window.location.protocol}${url.substring(url.indexOf(":") + 1)}`;
        console.debug(`Rederection url: ${redirectUrl}`);
        // Redirect to the provided URL
        //window.location.href = redirectUrl;
        return {isSuccess:true, data:redirectUrl};
      } else {
        
        return {isSuccess:false,data:result.data.result};
      }
  } catch (error:any) {
    console.error('API error : registerUser :', error);
    return {isSuccess:false , data : error.response.data};
    
  }
};

export const Login = async (username: string, password: string,token:string): Promise<LoginResponse> => {
  try {
    const result = await serviceApi.post(`app/auth/login?g-recaptcha-response=${token}`, {
      username,
    }, {
      headers: {
        'authcode': password,
      },
    });

    const responseData = result.data;

    return {
      isSuccess: responseData.isSuccess,
      message: responseData.message,
      result: responseData.result,
    };
  } catch (error: any) {
    console.error('API error: Login:', error);

    return {
      isSuccess: false,
      message: 'An error occurred during login.',
      result: undefined, // or set to null if you prefer
    };
  }
};

