import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Image, InputGroup, Card } from 'react-bootstrap';
import { sendEmail } from '../../services/emailService'; // Import the new service
import './contactPage.css';
import contact from '../../assets/img/contact.svg';

const ContactPage: React.FC = () => {
  const CONTACTUS_EMAIL = process.env.REACT_APP_CONTACTUS_EMAIL || 'yonoho2688@inpsur.com';
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false); // New state to track form submission

  const validateForm = () => {
    const errors: any = {};

    if (!formData.firstName) {
      errors.firstName = 'First name is required';
    }

    if (!formData.lastName) {
      errors.lastName = 'Last name is required';
    }

    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email address is invalid';
    }

    if (!formData.phoneNumber) {
      errors.phoneNumber = 'Phone number is required';
    } else if (!/^\+?\d{1,15}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = 'Phone number is invalid';
    }

    if (!formData.message) {
      errors.message = 'Message is required';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setHasSubmitted(true); // Set hasSubmitted to true when form is submitted
    if (validateForm()) {
      setIsSubmitting(true);
      const emailData = {
        to: CONTACTUS_EMAIL, // You can add more recipients separated by a semicolon
        subject: 'Contact Form Submission for T2D',
        text: `You have a new contact form submission from ${formData.firstName} ${formData.lastName}.\n\nEmail: ${formData.email}\nPhone Number: ${formData.phoneNumber}\n\nMessage: ${formData.message}`,
        html: `<p>You have a new contact form submission from ${formData.firstName} ${formData.lastName}.</p>
               <p><strong>Email:</strong> ${formData.email}</p>
               <p><strong>Phone Number:</strong> ${formData.phoneNumber}</p>
               <p><strong>Message:</strong> ${formData.message}</p>`,
      };

      const success = await sendEmail(emailData);
      setIsSuccess(success);
      setIsSubmitting(false);
    }
  };

  return (
    <Container className="contact-section py-1 pb-5 mb-4">
      <Row className="align-items-top">
        {/* Left Content */}
        <Col md={6}>
          <div className="contact-info p-4">
            <div className="badge-container mb-2">
              <span className="contact-badge">Contact Us</span>
            </div>
            <h2 className="fw-bold">We'd love to hear from you</h2>
            <p className="mb-4 contacthed">
              Need something cleared up? Here are our most frequently asked questions.
            </p>
            <div className="contact-image">
              <Image src={contact} alt="Contact" fluid />
            </div>
          </div>
        </Col>

        {/* Right Contact Form */}
        <Col lg={6}>
          <Card className="contact-form p-4" style={{ border: 'none', boxShadow: 'none' }}>
            <h3 className="fw-bold mb-1">Get in touch</h3>
            <p className="text-muted mb-4">We’d love to hear from you. Please fill out this form.</p>
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6} className="mb-3 mb-md-0">
                  <Form.Group controlId="firstName">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First name"
                      className={formErrors.firstName ? 'is-invalid' : ''}
                      value={formData.firstName}
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="lastName">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      className={formErrors.lastName ? 'is-invalid' : ''}
                      value={formData.lastName}
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="you@company.com"
                  className={formErrors.email ? 'is-invalid' : ''}
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="phoneNumber">
                <Form.Label>Phone number</Form.Label>
                <InputGroup>
                  <Form.Select aria-label="Country code" className="country-select">
                    <option value="US" selected>US</option>
                    <option value="CA">CA</option>
                    <option value="UK">UK</option>
                  </Form.Select>
                  <Form.Control
                    type="tel"
                    placeholder="+1 (555) 000-0000"
                    className={`phone-input ${formErrors.phoneNumber ? 'is-invalid' : ''}`}
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                  />
                </InputGroup>
                {formErrors.phoneNumber && (
                  <div className="invalid-feedback d-block">{formErrors.phoneNumber}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="message">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Leave us a message..."
                  className={formErrors.message ? 'is-invalid' : ''}
                  value={formData.message}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-check mb-3">
                <Form.Check
                  type="checkbox"
                  label="You agree to our friendly privacy policy."
                  id="privacy-policy"
                />
              </Form.Group>
              <Button type="submit" className="btn-purple w-100 rounded-pill" disabled={isSubmitting}>
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </Button>
              {hasSubmitted && isSuccess && (
                <p className="text-success mt-3">Your message has been sent successfully!</p>
              )}
              {hasSubmitted && !isSuccess && isSubmitting === false && (
                <p className="text-danger mt-3">There was an error sending your message. Please try again later.</p>
              )}
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactPage;