import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header/Header';
import Footer from './Footer/Footer';

const Layout: React.FC = () => {
  return (
    <div>
      <Header/>
      <main>
        <Outlet /> {/* This will render the matched child route */}
      </main>
      <Footer/>
    </div>
  );
};

export default Layout;
