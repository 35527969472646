import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { createBasePackage } from '../../services/packageService';
import NotificationToast from '../../components/NotificationToast';
import * as Icons from '../../assets/img/Icons';
import './CreateNewPackage.styles.css';

const { backIcon } = Icons;

const CreateNewPackage: React.FC = () => {
  const navigate = useNavigate();
  const [packageName, setPackageName] = useState('Free Package');
  const [baseValue, setBaseValue] = useState('10');
  const [status, setStatus] = useState(true);
  const [features, setFeatures] = useState<string[]>([
    'Public & Private Conversations',
    'Searchable History',
    'Group Video Calls',
    'Voice Messages',
    'Screen Sharing',
  ]);
  const [newFeature, setNewFeature] = useState('');
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ message: '', description: '' });

  // State to manage each unit's individual data
  const [units, setUnits] = useState<Array<{ unitType: string; unitOfMeasurement: string; quantity: string; description: string }>>([]);

  // Function to add a new feature when pressing Enter
  const handleFeatureInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewFeature(e.target.value);
  };

  const handleFeatureKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newFeature.trim() !== '') {
      e.preventDefault();
      addFeature(newFeature.trim());
      setNewFeature('');
    }
  };

  // Function to add a new feature
  const addFeature = (feature: string) => {
    if (!features.includes(feature)) {
      setFeatures([...features, feature]);
    }
  };

  // Function to remove a feature from the list
  const handleRemoveFeature = (feature: string) => {
    setFeatures(features.filter((f) => f !== feature));
  };

  // Function to handle adding a new unit
  const handleAddUnit = () => {
    if (units.length < 3) {
      setUnits([...units, { unitType: 'USERS', unitOfMeasurement: 'COUNT', quantity: '', description: '' }]);
    }
  };

  // Function to handle removing a unit
  const handleRemoveUnit = (index: number) => {
    setUnits(units.filter((_, i) => i !== index));
  };

  // Function to handle changes in each unit's fields
  const handleUnitChange = (index: number, field: string, value: string) => {
    const updatedUnits = [...units];
    updatedUnits[index] = { ...updatedUnits[index], [field]: value };
    if (field === 'unitType') {
      updatedUnits[index].unitOfMeasurement = value === 'STORAGE' ? 'GB' : 'COUNT';
    }
    setUnits(updatedUnits);
  };

  const handleBackClick = () => {
    navigate('/console/setting');
  };

  const handleSubmit = async () => {
    setLoading(true);
    
    // Constructing the payload to match the expected structure
    const payload = {
      PackageName: packageName,
      CurrencyUnit: 'USD',
      BaseValue: baseValue,
      IsActive: status,
      Features: features,
      BasePackageUnits: units.map((unit) => ({
        UnitType: unit.unitType || 'USERS', // Default to 'USERS' if empty
        Quantity: unit.quantity,
        Description: unit.description,
        UnitOfMeasurement: unit.unitOfMeasurement,
      })),
    };

    try {
      const token = ''; // Replace with actual token if needed
      const response = await createBasePackage(payload, token);
      if (response) {
        setToastMessage({ message: 'Success', description: 'Package created successfully!' });
      } else {
        setToastMessage({ message: 'Error', description: 'Failed to create package.' });
      }
    } catch (error) {
      setToastMessage({ message: 'Error', description: 'An unexpected error occurred.' });
    } finally {
      setShowToast(true);
      setLoading(false);
    }
  };

  return (
    <Container className="mt-5 packages-forms">
      <Row>
        <Col md={6}>
          <div className="form-section position-relative">
            <div className="backicon" onClick={handleBackClick} style={{ cursor: 'pointer' }}>
              <img className="image" src={backIcon} alt="backIcon" />
            </div>
            <h5 className="head-title mb-4 ml-20">Create Base Package</h5>
            <Form.Group className="d-flex form-group mb-3">
              <Form.Label className="form-label">Package Name</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                value={packageName}
                onChange={(e) => setPackageName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="d-flex form-group mb-3">
              <Form.Label className="form-label">Currency Unit</Form.Label>
              <Form.Control type="text" className="form-control" value="$ (USD)" disabled />
            </Form.Group>
            <Form.Group className="d-flex form-group mb-3">
              <Form.Label className="form-label">Base Value</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                value={baseValue}
                onChange={(e) => setBaseValue(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="d-flex form-group mb-3">
              <Form.Label className="form-label">Status</Form.Label>
              <div className="w-100 d-flex align-items-center">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=""
                  checked={status}
                  onChange={() => setStatus(!status)}
                />
                <span className="ml-2 font-14">{status ? 'Active' : 'Inactive'}</span>
              </div>
            </Form.Group>

            {/* Features Section */}
            <Form.Group className="form-group d-flex mb-4">
              <Form.Label className="form-label">Features</Form.Label>
              <div className="tag-input-container">
                {features.map((feature, index) => (
                  <span className="tag" key={index}>
                    {feature}
                    <button
                      type="button"
                      className="btn-close btn-sm ms-1"
                      aria-label="Close"
                      onClick={() => handleRemoveFeature(feature)}
                    ></button>
                  </span>
                ))}
                <input
                  type="text"
                  className="feature-input"
                  placeholder="Add new feature"
                  value={newFeature}
                  onChange={handleFeatureInputChange}
                  onKeyDown={handleFeatureKeyDown}
                />
              </div>
            </Form.Group>

            <Button
              variant="primary"
              className="btn-add-unit"
              onClick={handleAddUnit}
              disabled={units.length >= 3}
            >
              <i className="bi bi-plus"></i> Add Unit
            </Button>
          </div>
        </Col>

        {/* Render each Add Unit section dynamically */}
        {units.map((unit, index) => (
          <Col md={6} key={index}>
            <div className="form-section add-unit-section show">
              <h5 className="head-title mb-4">Add Unit {index + 1}</h5>
              <Form.Group className="d-flex form-group mb-3">
                <Form.Label className="form-label">Unit Type</Form.Label>
                <Form.Control
                  as="select"
                  className="form-control"
                  value={unit.unitType}
                  onChange={(e) => handleUnitChange(index, 'unitType', e.target.value)}
                >
                  <option value="USERS">Users</option>
                  <option value="SESSIONS">Sessions</option>
                  <option value="STORAGE">Storage</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="d-flex form-group mb-3">
                <Form.Label className="form-label">Unit Of Measurement</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  value={unit.unitOfMeasurement}
                  disabled
                />
              </Form.Group>
              <Form.Group className="d-flex form-group mb-3">
                <Form.Label className="form-label">Quantity</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  value={unit.quantity}
                  onChange={(e) => handleUnitChange(index, 'quantity', e.target.value)}
                />
              </Form.Group>
              <Form.Group className="form-group d-flex mb-4">
                <Form.Label className="form-label">Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="Description"
                  className="form-control"
                  placeholder="Add Description"
                  value={unit.description}
                  onChange={(e) => handleUnitChange(index, 'description', e.target.value)}
                  style={{ height: '130px' }}
                />
              </Form.Group>
              <Button
                variant="danger"
                className="btn-remove-unit"
                onClick={() => handleRemoveUnit(index)}
                style={{ backgroundColor: '#C4C4C4', borderColor: '#C4C4C4', color: '#3E3A3A', fontWeight: 'bold' }}
              >
                <i className="bi bi-trash"></i> Remove Unit
              </Button>
            </div>
          </Col>
        ))}
      </Row>

      <div className="text-center mt-4 mb-4 pb-3">
        <Button variant="success" className="btn-submit" onClick={handleSubmit} disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </Button>
      </div>

      {showToast && (
        <NotificationToast
          message={toastMessage.message}
          description={toastMessage.description}
          show={showToast}
          onClose={() => setShowToast(false)}
        />
      )}
    </Container>
  );
};

export default CreateNewPackage;
