import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './pages/Home/HomePage';
import FeaturePage from './pages/FeaturePage/FeaturePage';
import PricingPage from './pages/PricingPage/PricingPage';
import ContactPage from './pages/ContactPage/ContactPage';
import LoginPage from './pages/Login/LoginPage';
import ConsolePage from './pages/ConsolePage/ConsolePage'; // Console renamed from Dashboard
import SettingPage from './pages/SettingPage';
import NotFoundPage from './pages/NotFoundPage';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import SignupForm from './pages/Signup/SignupPage';
import OtpForm from './pages/opt/OtpForm';
import Registered from './pages/opt/Registered';
import Error500 from './pages/Error/Error500';
import CreateAddOns from './pages/CreateAddOns/CreateAddOns';
import CreateNewPackage from './pages/CreateNewPackage/CreateNewPackage'; // Import CreateNewPackage
import ManagePackages from './pages/ManagePackages/ManagePackages';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* Routes without Layout */}
        <Route path="signin" element={<PublicRoute restricted={true} element={<LoginPage />} />} />
        <Route path="signup" >
          <Route index element={<PublicRoute restricted={true} element={<SignupForm />} />} />
          <Route path="otp" element={<OtpForm />} />
          <Route path="registered" element={<Registered/>}/>
        </Route>
        {/* Routes with Layout */}
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="features" element={<FeaturePage />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route path="contact" element={<ContactPage />} />
 
          {/* Protected Routes */}
          <Route path="console" element={<PrivateRoute element={<HomePage />} />} />
          <Route path="console/setting" element={<PrivateRoute element={<ConsolePage />} />} />
          <Route path="console/setting/createaddons" element= {<PrivateRoute element={<CreateAddOns />} />} />
          <Route path="console/setting/createnewpackage" element={<PrivateRoute element={<CreateNewPackage />} />} /> {/* New route for CreateNewPackage */}
          <Route path="console/setting/managepackages" element={<PrivateRoute element={<ManagePackages />} />} />
 
          {/* Redirect or 404 for undefined paths */}
          <Route path="*" element={<NotFoundPage />} />
          <Route path="error" element={<Error500 />} />
        </Route>
      </Routes>
    </Router>
  );
};
 
export default App;
