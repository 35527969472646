import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import feature1 from '../../assets/img/feature1.svg';
import bgCoverForFeature from '../../assets/img/bg-coverforfeature.svg';
import spotlight from '../../assets/img/Spotlight.svg';
import spotlight2 from '../../assets/img/Spotlight2.svg';
import './featurepage.styles.css'; // Import the new CSS file

const FeaturePage: React.FC = () => {
  return (
    <div className="feature-page-container">
      <section className="bgcontabt">
        <Container className="mt-5">
          <Row>
            <Col md={6} className="d-flex align-items-center">
              <div className="position-relative">
                <Image src={feature1} alt="Feature 1" className="w-100 zindex-2" />
                <Image src={bgCoverForFeature} alt="Background Cover" className="width-100 bgposition position-absolute" />
              </div>
            </Col>
            <Col md={6}>
              <h1 className="maintitle">Features</h1>
              <h2 className="sub-title">Customer Support Made Easy</h2>
              <h5 className="text sub-t2">Click-to-Call for Support with Integrated Call Controls and DTMF Input</h5>
              <p className="text-contant pt-2 mb-2">
                “Click-To-Call” is a functionality that enables users to start a phone call with ease by simply
                clicking a button. This convenient feature is beneficial for customers seeking to reach out to
                customer support, service providers, sales representatives, or any other contacts quickly and
                efficiently. Click to Call supports only voice calls.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bgcontabt2">
        <Container>
          <Row>
            <Col md={6}>
              <h2 className="sub-title">Feature Spotlight</h2>
              <h5 className="text sub-t2">Click-to-Call Features for Optimal Customer Service</h5>
              <p className="text-contant pt-2 mb-2">
                <strong>Ease of Use:</strong> Initiate phone calls with a single click of a button, eliminating the need
                to manually dial numbers.
              </p>
              <p className="text-contant pt-2 mb-2">
                <strong>Speed and Efficiency:</strong> Enables quick connections to customer support, service providers,
                or sales representatives.
              </p>
              <p className="text-contant pt-2 mb-2">
                <strong>Voice Calls Only:</strong> Supports voice calls exclusively, ensuring clear communication without
                the need for additional setups.
              </p>
              <p className="text-contant pt-2 mb-2">
                <strong>Call Controls:</strong> Includes functionalities such as making calls, ending calls, muting/unmuting calls,
                ensuring control over the conversation.
              </p>
            </Col>
            <Col md={6} className="d-flex align-items-center">
              <Image src={spotlight} alt="Spotlight" className="w-100 zindex-2" />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bgcontabt3">
        <Container className="mt-2 mb-4 pb-4">
          <Row>
            <Col md={6} className="d-flex align-items-center">
              <Image src={spotlight2} alt="Spotlight 2" className="w-100 zindex-2" style={{ marginLeft: '-30px' }} />
            </Col>
            <Col md={6} className="pt-4">
              <p className="text-contant pt-2 mb-2">
                <strong>DTMF Input:</strong> Allows users to input Dual-Tone Multi-Frequency signals during calls,
                essential for interacting with automated systems and services (e.g., entering PINs, navigating menus).
              </p>
              <p className="text-contant pt-2 mb-2">
                <strong>Enhanced Accessibility:</strong> Provides a convenient way for users to reach out and interact with businesses or services.
              </p>
              <p className="text-contant pt-2 mb-2">
                <strong>Integration:</strong> Can be integrated with websites, applications, or digital platforms, enhancing customer engagement and support experience.
              </p>
              <p className="text-contant pt-2 mb-2">
                <strong>Customizable:</strong> Can be customized to fit specific business needs and branding requirements, ensuring seamless integration with existing systems.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default FeaturePage;
