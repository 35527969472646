import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Form, Button, Image, InputGroup } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import './Signup.styles.css';
import NotificationToast from '../../components/NotificationToast';
import { registerUser } from '../../services/userAuthService';
import EmailIcon from '../../assets/img/EmailIcon.svg';
import TenantIdIcon from '../../assets/img/TenantIdIcon.svg';  
import Logo from "../../assets/img/logo-big.svg";
import { Link, NavLink } from 'react-router-dom'; // Import NavLink
import { useNavigate } from 'react-router-dom';

const SignupForm: React.FC = () => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '', description: '' });
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [emailError, setEmailError] = useState('');
  const [tenantIdError, setTenantIdError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isValid = !emailError && !tenantIdError && email !== '' && tenantId !== '';
    setIsFormValid(isValid);
  }, [emailError, tenantIdError, email, tenantId]);

  const validateEmail = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      setEmailError('Email is required.');
    } else if (!emailRegex.test(value)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  const validateTenantId = (value: string) => {
    if (!value) {
      setTenantIdError('Tenant ID is required.');
    } else {
      setTenantIdError('');
    }
  };

  const handleSignup = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    validateEmail(email);
    validateTenantId(tenantId);

    if (emailError || tenantIdError) {
      setLoading(false);
      return;
    }

    try {
      if (!executeRecaptcha) {
        setToast({
          show: true,
          message: 'Registration failed',
          description: 'reCAPTCHA not loaded.',
        });
        setLoading(false);
        return;
      }

      const token = await executeRecaptcha("SignupForm");

      const apiResponse = await registerUser(email, tenantId, {}, token);

      if (!apiResponse.isSuccess) {
        setToast({
          show: true,
          message: 'Registration failed',
          description: apiResponse.data.message ?? 'An error occurred during registration.',
        });
        return;
      }
      else{
        setToast({
          show: true,
          message: 'Registration',
          description: `OTP Number sent to ${email}.`,
        });
  
        setEmail('');
        setTenantId('');

        // Data to pass to the next page
        const dataToPass = {
          url: apiResponse.data,
          email
        };

        // Navigate to /signup/otp and pass the data
        navigate('/signup/otp', { state: dataToPass });
      }
      
    } catch (error) {
      console.error('API error:', error);

      setToast({
        show: true,
        message: 'Registration failed',
        description: 'An error occurred during registration.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid className="sigupform vh-100 d-flex">
      <Row className="w-100">
        <Col md={6} className="bg-light pt-3">
          <NavLink to="/"> 
            <Image src={Logo} alt="Tetherfi Logo" fluid />
          </NavLink>
        </Col>
        <Col md={6} className="d-flex flex-column">
          <div className="d-flex justify-content-end mt-3 me-4">
            <span className="text-muted me-2">Do you have an account?</span>            
            <Link to="/signin" className="text-primary fw-bold">Sign in</Link>
          </div>
          <div className="d-flex align-items-center justify-content-center flex-grow-1">
            <div className="w-75">
              <h2 className="fw-bold mb-3">Create Account</h2>
              {/* <p className="text-muted mb-4 font-medium-t">
                Please fill to create a T2D account.
              </p> */}
              <Form onSubmit={handleSignup}>
                <Form.Group className="mb-4" controlId="formEmail">
                  <InputGroup hasValidation>
                    <span className="input-group-text bg-light round-r form-filed">
                      <Image src={EmailIcon} alt="Email Icon" />
                    </span>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Email"
                      className="form-control form-control-lg bg-light font-s-14"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={(e) => validateEmail(e.target.value)} // Validate on blur
                      isInvalid={!!emailError}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {emailError}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-4" controlId="formTenantId">
                  <InputGroup hasValidation>
                    <span className="input-group-text bg-light round-r form-filed">
                      <Image src={TenantIdIcon} alt="Tenant ID Icon" />
                    </span>
                    <Form.Control
                      type="text"
                      name="tenantId"
                      placeholder="Tenant ID"
                      className="form-control form-control-lg bg-light font-s-14"
                      value={tenantId}
                      onChange={(e) => {
                        setTenantId(e.target.value);
                        validateTenantId(e.target.value);
                      }}
                      isInvalid={!!tenantIdError}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {tenantIdError}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Button
                  className="btn btn-primary2 btn-lg w-100 rounded-pill"
                  type="submit"
                  disabled={loading || !isFormValid}
                >
                  {loading ? 'Creating Account...' : 'Create Account'}
                </Button>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <NotificationToast
        show={toast.show}
        message={toast.message}
        description={toast.description}
        onClose={() => setToast({ show: false, message: '', description: '' })}
      />
    </Container>
  );
};

export default SignupForm;
