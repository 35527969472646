import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup ,Image} from 'react-bootstrap';
import './Registered.css';
import Logo from "../../assets/img/logo-big.svg";
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Registered: React.FC = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [authCode, setAuthCode] = useState("");

    useEffect(() => {
        if (location.state && location.state.authCode) {
            setAuthCode(location.state.authCode);
        }
        else{
           // navigate("/error")
        }
    }, [location.state]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(authCode);
        alert("Copied the text: " + authCode);
    };
    

    return (
        <Container fluid className="loginform vh-100 d-flex">
            <Row className="w-100">
                {/* Left Branding Section */}
                <Col md={6} className="bg-light pt-3">
                    <Image src={Logo} alt="Tetherfi Logo" fluid />
                </Col>

                {/* Right Form Section */}
                <Col md={6} className="d-flex flex-column">
                    <div className="d-flex justify-content-end mt-3 me-4">
                        <span className="text-muted">Do you have an account?</span>
                        <Link to="/signin" className="text-primary fw-bold">Sign in</Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-center flex-grow-1">
                        <div className="col-lg-10 col-md-11 col-sm-11 text-center auth-card">
                            <h3 className="mb-3 fw-bold">Registration Successful</h3>
                            <p className="text-success mb-2">Keep your Code safe and do not share it with others. If lost it needs to be regenerated.</p>
                            <p className="text-muted mb-4">Default package assigned!</p>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-muted">AuthCode</Form.Label>
                                    <InputGroup className="position-relative">
                                        <Form.Control
                                            type="text"
                                            id="authCode"
                                            value={authCode}
                                            readOnly
                                            className="text-center auth-code-input"
                                        />
                                        <span className="copy-icon position-absolute" onClick={copyToClipboard}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                                <g id="_file_copy" data-name=" file_copy" transform="translate(-474.23 -1482)">
                                                    <rect id="Path" width="17" height="17" transform="translate(474.23 1482)" fill="rgba(0,0,0,0)" />
                                                    <path id="Icon" d="M12.526,16H4.413a1.462,1.462,0,0,1-1.466-1.454L2.954,4.364A1.462,1.462,0,0,1,4.421,2.909H8.967a1.481,1.481,0,0,1,1.046.429l3.559,3.513A1.447,1.447,0,0,1,14,7.877v6.669A1.466,1.466,0,0,1,12.526,16ZM8.842,4h0V7.273A.733.733,0,0,0,9.579,8h3.316ZM.737,11.636A.733.733,0,0,1,0,10.909V1.455A1.466,1.466,0,0,1,1.474,0H9.579a.733.733,0,0,1,.737.728.733.733,0,0,1-.737.727H2.21a.733.733,0,0,0-.737.727v8.727A.733.733,0,0,1,.737,11.636Z" transform="translate(475.23 1483)" fill="#7c59cb" />
                                                </g>
                                            </svg>
                                        </span>
                                    </InputGroup>
                                </Form.Group>
                                <Button className="btn-primary2 btn-lg px-4 width-200 rounded-pill mb-3 mt-3" onClick={() => navigate("/")}>
                                    Continue
                                </Button>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Registered;
