import axios from "axios";

const BASE_URL = process.env.REACT_APP_TCM_POSTMASTER_BASE_URL || 'http://localhost:3003';

const apiService = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

export const sendEmail = async (emailData: {
    to: string;
    subject: string;
    text: string;
    html: string;
}): Promise<boolean> => {
    try {
        const response = await apiService.post("/email", emailData);
        if (response.status === 200) {
            return true;
        } else {
            throw new Error("Failed to send email");
        }
    } catch (error) {
        console.error("Error sendEmail:", error);
        return false;
    }
};
