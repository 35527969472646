import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Tab, Nav, Button, Spinner } from 'react-bootstrap';
import './ManagePackages.styles.css';
import {
  fetchAddonData,
  fetchCurrentPackage,
  fetchPackageData,
} from '../../services/packageService';
import { useSelector } from 'react-redux';
import { selectTokenInfo } from '../../store/slices/auth/authentication.slice';
import NotificationToast from '../../components/NotificationToast';
import {
  GetPackageResponse,
  AddonPackage,
  BasePackage,
  CurrentPackages,
  BasePackageUnit,
} from '../../components/interfaces/interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
 
const ManagePackages: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activePlan, setActivePlan] = useState('');
  const [basePackages, setBasePackages] = useState<BasePackage[] | undefined>();
  const [addonPackages, setAddonPackages] = useState<AddonPackage[] | undefined>();
  const [currentPackage, setCurrentPackage] = useState<CurrentPackages | null>(null);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastDescription, setToastDescription] = useState('');
  const [activeTab, setActiveTab] = useState<string>(location.state?.activeTab || 'packages');
 
  const tokenInfo = useSelector(selectTokenInfo);
 
  const handleCardClick = (plan: string) => {
    setActivePlan(plan);
  };
 
  const handleFetchPackages = async () => {
    try {
      setLoading(true);
      if (tokenInfo?.bearerToken) {
        const response: GetPackageResponse = await fetchPackageData('', tokenInfo.bearerToken);
        if (response.isSuccess) {
          setBasePackages(response.result);
        } else {
          setToastMessage('Error');
          setToastDescription('Failed to fetch base packages.');
          setShowToast(true);
        }
      }
    } catch (error) {
      console.error('Error fetching base packages:', error);
    } finally {
      setLoading(false);
    }
  };
 
  const handleFetchAddons = async () => {
    try {
      setLoading(true);
      if (tokenInfo?.bearerToken) {
        const response = await fetchAddonData('', tokenInfo.bearerToken);
        if (response.isSuccess) {
          setAddonPackages(response.result);
        } else {
          setToastMessage('Error');
          setToastDescription('Failed to fetch add-on packages.');
          setShowToast(true);
        }
      }
    } catch (error) {
      console.error('Error fetching add-ons:', error);
    } finally {
      setLoading(false);
    }
  };
 
  const loadCurrentPackage = async () => {
    try {
      if (tokenInfo?.bearerToken) {
        const response: CurrentPackages | null = await fetchCurrentPackage(tokenInfo.bearerToken);
        setCurrentPackage(response);
      }
    } catch (error) {
      console.error('Error fetching current package:', error);
    }
  };
 
  useEffect(() => {
    if (activeTab === 'packages') {
      handleFetchPackages();
    } else if (activeTab === 'addons') {
      handleFetchAddons();
    }
    loadCurrentPackage();
  }, [activeTab, tokenInfo?.bearerToken]);
 
  // Handles tab change to fetch data accordingly
  const handleTabSelect = (key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  };
 
  // Function to handle navigation to the Create New Package/Add-Ons pages
  const handleCreateClick = () => {
    if (activeTab === 'packages') {
      navigate('/console/setting/createnewpackage');
    } else if (activeTab === 'addons') {
      navigate('/console/setting/createaddons');
    }
  };
 
  // Function to determine the button label based on the active tab
  const getCreateButtonLabel = () => {
    return activeTab === 'packages' ? '+ Create New Package' : '+ Create New Add-Ons';
  };
 
  // Function to get unit values based on unit type
  const getUnitValue = (units: BasePackageUnit[], unitType: string): string => {
    const unitTypeMapping: { [key: string]: string } = {
      Users: 'USERS',
      'Concurrent Sessions': 'SESSIONS',
      Storage: 'STORAGE',
    };
 
    const mappedType = unitTypeMapping[unitType] || unitType;
 
    // Fetch the unit based on the mapped type
    const unit = units.find((u) => u.UnitType.toUpperCase() === mappedType.toUpperCase());
 
    // Format quantity without decimal places and omit "COUNT" if present
    const formattedQuantity = unit ? parseFloat(unit.Quantity).toFixed(0) : '0';
    const unitOfMeasurement = unit?.UnitOfMeasurement === 'COUNT' ? '' : unit?.UnitOfMeasurement || '';
 
    return unit ? `${formattedQuantity} ${unitOfMeasurement}`.trim() : '0';
  };
 
  // Function to format the display of AddonPackage based on UnitType with quantity shown
  const formatAddonDisplay = (addonPackage: AddonPackage): string => {
    const quantity = parseFloat(addonPackage.QuantityPerUnit.toString()).toFixed(0);
    return `${quantity} ${addonPackage.UnitType}`; // Formats the display, e.g., "10 Users" or "5 Storage"
  };
 
  return (
    <div className="manage-packages">
      <Container className="mt-5 pricing-section">
        <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
          <Row className="align-items-center justify-content-between mb-3">
            <Col md="auto">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="packages" onClick={handleFetchPackages}>
                    Packages
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="addons" onClick={handleFetchAddons}>
                    Add-Ons
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md="auto">
              <Button
                id="CreateNewButton"
                className="btn-blue"
                onClick={handleCreateClick}
              >
                {getCreateButtonLabel()}
              </Button>
            </Col>
          </Row>
 
          <h2 className="fw-bold mb-4 text-left tittle-admin">
            Welcome to Subscription Management Platform
          </h2>
 
          <Tab.Content>
            <Tab.Pane eventKey="packages">
              <Row className="g-4">
                {loading ? (
                  <Spinner animation="border" />
                ) : basePackages && basePackages.length > 0 ? (
                  basePackages.map((packageData) => (
                    <Col md={4} key={packageData.Id}>
                      <div
                        className={`card h-100 shadow-sm border-light ${
                          activePlan === packageData.PackageName ? 'active-plan' : ''
                        }`}
                        onClick={() => handleCardClick(packageData.PackageName)}
                      >
                        <div className="card-header bg-transparent border-bottom-0 text-center">
                          <span className="badge bg-light-purple text-white px-3 py-1">
                            {packageData.PackageName}
                          </span>
                          <div className="card-icons">
                            <i className="fas fa-pencil-alt"></i>
                            <i className="fas fa-trash-alt"></i>
                          </div>
                        </div>
                        <div className="card-body">
                          <h5 className="fw-bold text-left">
                            {getUnitValue(packageData.BasePackageUnits, 'Users')} Users
                          </h5>
                          <h5 className="fw-bold text-left">
                            {getUnitValue(packageData.BasePackageUnits, 'Concurrent Sessions')} Concurrent Sessions
                          </h5>
                          <h5 className="fw-bold text-left">
                            {getUnitValue(packageData.BasePackageUnits, 'Storage')} Storage
                          </h5>
                          <h3 className="card-price text-purple mb-3">
                            {packageData.BaseValue} {packageData.CurrencyUnit} / month
                          </h3>
                          <ul className="list-unstyled mb-4 border-0">
                            {packageData.Features?.map((feature, index) => (
                              <li key={index}>
                                <i className="fas fa-check text-purple"></i> {feature}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <p>No Packages Available</p>
                )}
              </Row>
            </Tab.Pane>
 
            {/* Updated Add-Ons Tab */}
            <Tab.Pane eventKey="addons">
              <Row className="g-4">
                {loading ? (
                  <Spinner animation="border" />
                ) : addonPackages && addonPackages.length > 0 ? (
                  addonPackages.map((addonPackage) => (
                    <Col md={4} key={addonPackage.Id}>
                      <div
                        className={`card h-100 shadow-sm border-light ${
                          activePlan === addonPackage.UnitName ? 'active-plan' : ''
                        }`}
                        onClick={() => handleCardClick(addonPackage.UnitName)}
                      >
                        <div className="card-body text-center">
                          <span className="badge bg-silver addon-package-name">{addonPackage.UnitName}</span>
                          <div className="card-icons">
                            <i className="fas fa-pencil-alt"></i>
                            <i className="fas fa-trash-alt"></i>
                          </div>
                          <h5 className="card-title">{formatAddonDisplay(addonPackage)}</h5> {/* Updated display */}
                          <p className="card-text price">
                            {addonPackage.PricePerUnit} {addonPackage.CurrencyUnit} / month
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <p>No Add-Ons Available</p>
                )}
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
 
      <NotificationToast
        message={toastMessage}
        description={toastDescription}
        show={showToast}
        onClose={() => setShowToast(false)}
      />
    </div>
  );
};
 
export default ManagePackages;