import React, { useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

const NotificationToast: React.FC<{ message: string; description: string; show: boolean; onClose: () => void }> = ({
  message,
  description,
  show,
  onClose,
}) => {
  return (
    <ToastContainer position="top-end" className="p-3">
      <Toast show={show} onClose={onClose} bg="info" delay={5000} autohide>
        <Toast.Header>
          <strong className="me-auto">{message}</strong>
        </Toast.Header>
        <Toast.Body>{description}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default NotificationToast;
