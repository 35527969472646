import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Tab, Nav, Spinner, Alert } from 'react-bootstrap';
import './pricingPage.styles.css';
import { fetchAddonData, fetchCurrentPackage, fetchPackageData } from "../../services/packageService";
import { GetPackageResponse, BasePackage, CurrentPackages, AddonPackage, GetAddonPackagesResponse, TokenInfo } from "../../components/interfaces/interfaces";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useSelector } from "react-redux";
import { selectTokenInfo } from "../../store/slices/auth/authentication.slice";
import AddonPackageCard from '../Package/AddonPackageCard';
import BasePackageCard from '../Package/BasePackageCard';
import NotificationToast from '../../components/NotificationToast';

const PricingPage = () => {
  const [responseData, setResponseData] = useState<BasePackage[] | undefined>();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const tokenInfo = useSelector(selectTokenInfo) as TokenInfo | null;
  const [currentPackage, setCurrentPackage] = useState<CurrentPackages | null>(null);
  const [addonData, setAddonData] = useState<AddonPackage[] | undefined>();
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({ show: false, message: '', description: '' }); // Notification state
  const didMountRef = useRef(false);
  const packagedidMountRef = useRef(false);

  const showNotification = (message: string, description: string) => {
    setNotification({ show: true, message, description });
  };

  const loadCurrentPackage = async () => {
    try {
      if (tokenInfo?.bearerToken) {
        const response: CurrentPackages | null = await fetchCurrentPackage(tokenInfo.bearerToken);
        if (!response) {
          console.error("Error fetching current package data.");
          showNotification('Error', 'Failed to load current package data.');
        }
        setCurrentPackage(response);
      }
    } catch (error) {
      console.error("Error fetching current package data.", error);
      showNotification('Error', 'Failed to load current package data.');
    }
  };

  useEffect(() => {
    const fetchDataFromApi = async () => {
      if (!executeRecaptcha) {
        console.error("reCAPTCHA not loaded");
        return;
      }
      try {
        const token = await executeRecaptcha("Pricing");
        const response: GetPackageResponse = await fetchPackageData(token, tokenInfo?.bearerToken);
        if (response.isSuccess) {
          setResponseData(response.result);
        } else {
          console.error("Error fetching data:", response.error);
          setResponseData(undefined);
          showNotification('Error', 'Failed to load package data.');
        }
      } catch (error) {
        console.error("Error getting reCAPTCHA token:", error);
        showNotification('Error', 'Failed to verify reCAPTCHA.');
      } finally {
        setLoading(false);
      }
    };

    const fetchAddonDataFromApi = async () => {
      try {
        const response: GetAddonPackagesResponse = await fetchAddonData("", tokenInfo?.bearerToken);
        if (!response.isSuccess) {
          console.error("Error fetching data:", response.error);
          showNotification('Error', 'Failed to load addon data.');
        }
        setAddonData(response.result);
      } catch (error) {
        console.error("Error getting reCAPTCHA token:", error);
        showNotification('Error', 'Failed to load addon data.');
      } finally {
        setLoading(false);
      }
    };

    if (tokenInfo?.bearerToken && !didMountRef.current) {
      setLoading(true);
      loadCurrentPackage();
      fetchAddonDataFromApi();
      didMountRef.current = true;
    }

    if (!packagedidMountRef.current) {
      fetchDataFromApi();
      packagedidMountRef.current = true;
    }
  }, [tokenInfo?.bearerToken]);

  return (
    <section className="pricing-section py-5">
      <Container className="text-center py-4">
        <h3 className="h2 fw-bold mb-3">Choose Your Right Plan!</h3>
        <p className="text-contant-p mb-4">
          Choose the plan that fits your needs and budget. We offer flexible subscription options designed to provide you with the best value for your investment. Whether you're a small business looking for essential features or an enterprise requiring advanced capabilities, we have a plan for you.
        </p>

        <Tab.Container defaultActiveKey="monthly">
          <Nav variant="tabs" className="justify-content-center custom-nav-tabs mb-4">
            <Nav.Item>
              <Nav.Link eventKey="monthly">Monthly</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="yearly">Yearly (save 15%)</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content className="pricing-table">
            <Tab.Pane eventKey="monthly">
              <Row className="g-4">
                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : responseData && responseData.length > 0 ? (
                  <>
                    {responseData.map((packageData) => (
                      <Col md={4} key={packageData.Id}>
                        <BasePackageCard
                          data={packageData}
                          currentPackages={currentPackage}
                          tokenInfo={tokenInfo}
                          reloadCurrentPackage={loadCurrentPackage}
                          showNotification={showNotification} // Pass notification handler
                        />
                      </Col>
                    ))}

                    {addonData && addonData.length > 0 && (
                      addonData.map((addonPackageData) => (
                        <AddonPackageCard
                          key={addonPackageData.Id}
                          addonPackageData={addonPackageData}
                          currentPackages={currentPackage}
                          showNotification={showNotification} // Pass notification handler
                        />
                      ))
                    )}
                  </>
                ) : (
                  <Alert variant="danger">No packages available at this time.</Alert>
                )}
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="yearly">
              <p>Yearly pricing plans coming soon...</p>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>

      <NotificationToast
        message={notification.message}
        description={notification.description}
        show={notification.show}
        onClose={() => setNotification({ ...notification, show: false })}
      />
    </section>
  );
};

export default PricingPage;
