import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import NotificationToast from '../../components/NotificationToast'; // Import the notification component
import './CreateAddOns.styles.css'; // Link to your custom CSS
import * as Icons from '../../assets/img/Icons';
import { SaveAddon } from '../../services/packageService'; // Import SaveAddon function from your service
import { AddonPackage } from '../../components/interfaces/interfaces'; // Import AddonPackage interface
import { useSelector } from 'react-redux';
import { selectTokenInfo } from '../../store/slices/auth/authentication.slice'; // Import selector
 
const { backIcon } = Icons;
 
const CreateAddOns: React.FC = () => {
  const navigate = useNavigate();
  const tokenInfo = useSelector(selectTokenInfo);
  const [formValues, setFormValues] = useState<AddonPackage>({
    UnitName: 'Free Package',
    UnitType: 'USERS',
    UnitOfMeasurement: 'COUNT',
    CurrencyUnit: 'USD',
    PricePerUnit: 0,
    QuantityPerUnit: 1,
    IsActive: true,
  });
 
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastDescription, setToastDescription] = useState('');
 
  const handleBackClick = () => {
    navigate('/console/setting');
  };
 
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: name === 'PricePerUnit' || name === 'QuantityPerUnit' ? parseFloat(value) : value,
    }));
  };
 
  const handleUnitTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newUnitType = e.target.value;
    const newUnitOfMeasurement = newUnitType === 'STORAGE' ? 'GB' : 'COUNT';
    setFormValues((prevValues) => ({
      ...prevValues,
      UnitType: newUnitType,
      UnitOfMeasurement: newUnitOfMeasurement,
    }));
  };
 
  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      IsActive: e.target.checked,
    }));
  };
 
  const handleSubmit = async () => {
    try {
      const newAddonPackage = await SaveAddon(formValues, tokenInfo?.bearerToken ?? '');
 
      if (newAddonPackage) {
        setToastMessage('Addon Package');
        setToastDescription('Addon package created successfully.');
      } else {
        throw new Error('Failed to create addon package');
      }
    } catch (error) {
      setToastMessage('Error');
      setToastDescription('Failed to create addon package.');
    } finally {
      setShowToast(true);
    }
  };
 
  return (
    <Container className="mt-5 packages-forms">
      <Row>
        <Col md={6}>
          <div className="form-section position-relative">
            <div className="backicon" onClick={handleBackClick} style={{ cursor: 'pointer' }}>
              <img className="image" src={backIcon} alt="backIcon" />
            </div>
            <h5 className="head-title mb-4 ml-20">Create Add-Ons</h5>
 
            <Form.Group className="d-flex form-group mb-3">
              <Form.Label className="form-label">Unit Name</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                name="UnitName"
                value={formValues.UnitName}
                onChange={handleInputChange}
              />
            </Form.Group>
 
            <Form.Group className="d-flex form-group mb-3">
              <Form.Label className="form-label">Unit Type</Form.Label>
              <Form.Select
                className="form-control"
                name="UnitType"
                value={formValues.UnitType}
                onChange={handleUnitTypeChange}
              >
                <option value="USERS">Users</option>
                <option value="SESSIONS">Sessions</option>
                <option value="STORAGE">Storage</option>
              </Form.Select>
            </Form.Group>
 
            <Form.Group className="d-flex form-group mb-3">
              <Form.Label className="form-label">Unit of Measurement</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                name="UnitOfMeasurement"
                value={formValues.UnitOfMeasurement}
                disabled
              />
            </Form.Group>
 
            <Form.Group className="d-flex form-group mb-3">
              <Form.Label className="form-label">Currency Unit</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                name="CurrencyUnit"
                value={formValues.CurrencyUnit}
                disabled
              />
            </Form.Group>
 
            <Form.Group className="d-flex form-group mb-3">
              <Form.Label className="form-label">Price per Unit</Form.Label>
              <Form.Control
                type="number"
                className="form-control"
                name="PricePerUnit"
                value={formValues.PricePerUnit}
                onChange={handleInputChange}
              />
            </Form.Group>
 
            <Form.Group className="d-flex form-group mb-3">
              <Form.Label className="form-label">Quantity per Unit</Form.Label>
              <Form.Control
                type="number"
                className="form-control"
                name="QuantityPerUnit"
                value={formValues.QuantityPerUnit}
                onChange={handleInputChange}
              />
            </Form.Group>
 
            <Form.Group className="d-flex form-group mb-3 align-items-center">
              <Form.Check
                type="switch"
                className="toggle-switch"
                checked={formValues.IsActive}
                label={<span className="ml-2 font-14">Active</span>}
                onChange={handleSwitchChange}
              />
            </Form.Group>
            {/* <Button className="btn btn-add-unit" onClick={handleSubmit}>
              <i className="fas fa-plus"></i> Add Unit
            </Button> */}
          </div>
        </Col>
      </Row>
      <div className="text-center mt-4 mb-4 pb-3">
        <Button className="btn btn-submit" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
 
      <NotificationToast
        message={toastMessage}
        description={toastDescription}
        show={showToast}
        onClose={() => setShowToast(false)}
      />
    </Container>
  );
};
 
export default CreateAddOns;