import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

interface PublicRouteProps {
  element: React.ReactElement;
  restricted?: boolean; // If true, redirects authenticated users
}

const PublicRoute: React.FC<PublicRouteProps> = ({
  element,
  restricted = false,
}) => {
  const isAuthenticated = useSelector((state: RootState) => state.authentication.isLoggedIn);

  // Redirect if authenticated and restricted is true
  return isAuthenticated && restricted ? <Navigate to="/console" /> : element;
};

export default PublicRoute;
