import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const Error500: React.FC = () => {
  return (
    <Container fluid className="d-flex flex-column justify-content-center align-items-center vh-100 bg-white text-center">
      <Row className="justify-content-center">
        <Col xs={12}>
          <div
            className="error-image mb-4"
            style={{
              width: '300px',
              height: '300px',
              backgroundImage: "url('https://placehold.co/300x300')",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h1 className="error-code mb-3" style={{ fontSize: '48px', color: '#333' }}>500</h1>
          <p className="error-message mb-4" style={{ fontSize: '18px', color: '#666' }}>Sorry, something went wrong.</p>
          <Button href="#" variant="primary" className="back-home" style={{ padding: '10px 20px', borderRadius: '5px' }}>
            Back Home
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Error500;
