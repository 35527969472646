import {
  AddonPackage,
  GetAddonPackagesResponse,
  GetPackageResponse,
  BasePackage,
  CurrentPackages,
} from '../components/interfaces/interfaces';
import axios from "axios";

const BASE_URL = process.env.REACT_APP_PACKAGE_SERVICE_BASE_URL || 'http://localhost:18082';

const apiService = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    // You may include other common headers here
  },
});

export const fetchPackageData = async (
  recaptcha: string,
  authorization?: string
): Promise<GetPackageResponse> => {
  try {
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };

    if (authorization) {
      headers["authorization"] = authorization;
    }
    const options: RequestInit = {
      method: "GET",
      headers: headers,
    };

    const url = authorization
      ? `${BASE_URL}/packages/base`
      : `${BASE_URL}/packages/base?g-recaptcha-response=${recaptcha}`;
    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data.isSuccess && Array.isArray(data.result)) {
      const filteredResult = data.result.filter(
        (item: any) => item.IsActive === true
      );
      return { isSuccess: true, result: filteredResult };
    } else {
      return { isSuccess: false, result: undefined };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { isSuccess: false, error: "Error fetching data" };
  }
};

export const fetchAddonData = async (
  recaptcha: string,
  authorization?: string
): Promise<GetAddonPackagesResponse> => {
  try {
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };

    if (authorization) {
      headers["authorization"] = authorization;
    }
    const options: RequestInit = {
      method: "GET",
      headers: headers,
    };

    const url = authorization
      ? `${BASE_URL}/packages/addon`
      : `${BASE_URL}/packages/addon?g-recaptcha-response=${recaptcha}`;
    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data.isSuccess && data.result) {
      return { isSuccess: true, result: data.result };
    } else {
      return { isSuccess: false, result: undefined };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { isSuccess: false, error: "Error fetching data" };
  }
};

export const fetchCurrentPackage = async (
  authorization: string
): Promise<CurrentPackages | null> => {
  try {
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
      authorization,
    };

    const options: RequestInit = {
      method: "GET",
      headers: headers,
    };

    const response = await fetch(`${BASE_URL}/packages/current`, options);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data.isSuccess && data.result) {
      return data.result;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const createBasePackage = async (
  data: any,
  token: string
): Promise<BasePackage | null> => {
  try {
    //throw new Error("service down");
    const response = await apiService.post("/package/base", data, {
      headers: {
        authorization: token,
      },
    });

    if (response.data.isSuccess && response.data.result) {
      return response.data.result;
    } else {
      throw new Error("API response does not contain a valid result");
    }
  } catch (error) {
    console.error("Error createBasePackage:", error);
    return null;
  }
};

export const SaveAddon = async (
  data: any,
  token: string
): Promise<AddonPackage | null> => {
  try {
    const response = await apiService.post("/package/addon", data, {
      headers: {
        authorization: token,
      },
    });

    if (response.data.isSuccess && response.data.result) {
      return response.data.result;
    } else {
      throw new Error("API response does not contain a valid result");
    }
  } catch (error) {
    console.error("Error createAddon:", error);
    return null;
  }
};


export const BuyBasePackage = async (
  id: string,
  token: string
): Promise<boolean> => {
  try {
    const response = await apiService.post(`/package/base/${id}/buy`, {}, {
      headers: {
        authorization: token,
      },
    });

    return response.data.isSuccess;
  } catch (error) {
    console.error("Error BuyBasePackage:", error);
    return false;
  }
};


export const BuyAddonPackage = async (
  id: string,
  quantity: string,
  token: string
): Promise<boolean> => {
  try {
    const response = await apiService.post(`package/addon/${id}/buy/${quantity}`, {}, {
      headers: {
        authorization: token,
      },
    });

    return response.data.isSuccess;
  } catch (error) {
    console.error("Error BuyAddonPackage:", error);
    return false;
  }
};
