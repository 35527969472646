import React, { useState, useEffect } from 'react';
import { Card, Button, Badge, ListGroup, Spinner } from 'react-bootstrap';
import { BasePackage, BasePackageUnit, CurrentPackages, TokenInfo } from '../../components/interfaces/interfaces';
import { BuyBasePackage } from '../../services/packageService';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import './BasepackageCard.styles.css';


interface BasePackageCardProps {
  data: BasePackage;
  currentPackages: CurrentPackages | null;
  tokenInfo: TokenInfo | null;
  reloadCurrentPackage: () => void;
  showNotification: (message: string, description: string) => void;
}

const BasePackageCard: React.FC<BasePackageCardProps> = ({ data, currentPackages, tokenInfo, reloadCurrentPackage, showNotification }) => {
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allowedToBuy, setAllowedToBuy] = useState(true);
  const isAuthenticated = useSelector(
    (state: RootState) => state.authentication.isLoggedIn
  );

  useEffect(() => {
    if (currentPackages && currentPackages.ActiveBasePackages) {
      setIsActive(currentPackages.ActiveBasePackages.some(p => p.BasePackageId === data.Id));
      setAllowedToBuy(!currentPackages.ActiveBasePackages.some(p => parseFloat(p.TransactionPrice) > parseFloat(data.BaseValue)));
    }
  }, [currentPackages]);

  const handleBuyBasePackage = async () => {
    if (!tokenInfo?.bearerToken) {
      showNotification('Error', 'You must be registered with the system to buy a package.');
      return;
    }

    setLoading(true);

    try {
      const response = await BuyBasePackage(data.Id, tokenInfo.bearerToken);
      if (response) {
        reloadCurrentPackage();
        showNotification('Success', 'Package purchased successfully.');
      }
    } catch (error) {
      console.error('Error buying package:', error);
      showNotification('Error', 'Failed to purchase package.');
    } finally {
      setLoading(false);
    }
  };

  const formatUnits = (unit: BasePackageUnit) => {
    switch (unit.UnitType) {
      case "USERS":
        return `${unit.Quantity} Users`;
      case "STORAGE":
        return `${unit.Quantity} ${unit.UnitOfMeasurement}`;
      case "SESSIONS":
        return `${parseFloat(unit.Quantity).toFixed(0)} Concurrent Sessions`;
      default:
        return '';
    }
  };

  return (
    <Card className={`h-100 shadow-sm ${isActive ? 'border-success' : 'border-light'}`}>
      <Card.Header
        className="bg-transparent text-left"
        style={{ borderBottom: 'none', display: 'flex', justifyContent: 'flex-start' }} // Aligns content to the left // Removes bottom border from the card header
      >
        <Badge className="bg-bronze text-white">{data.PackageName}</Badge>
      </Card.Header>
      <Card.Body>
        {/* Package details as a list */}
        <ListGroup variant="flush" className="mb-3">
          {data.BasePackageUnits.map(unit => (
            <ListGroup.Item
              key={unit.Id}
              style={{ textAlign: 'left', fontSize: '1.2rem', fontWeight: 'bold', borderBottom: 'none' }} // Removes bottom border from list items
            >
              <h5 className="card-title">{formatUnits(unit)}</h5>
            </ListGroup.Item>
          ))}
        </ListGroup>

        <h3
          className="card-price text-dark mb-3"
          style={{ textAlign: 'left' }}
        >
          {data.BaseValue} {data.CurrencyUnit} <span className="text-muted small-font">/ month</span>
        </h3>

        {/* Features Section */}
        <ListGroup variant="flush" className="mb-4">
          {data.Features && data.Features.map((feature, index) => (
            <ListGroup.Item
              key={index}
              style={{ color: 'black', fontSize: '16px', lineHeight: '19px', textAlign: 'left', borderBottom: 'none' }} // Removes bottom border from list items
            >
              <i
                className="bi bi-check"
                style={{ color: '#DA70D6', marginRight: '0.5rem', fontSize: '1.5rem' }}
              ></i> 
              {feature}
            </ListGroup.Item>
          ))}
        </ListGroup>

          <Button
              variant={isActive ? "outline-secondary" : "primary"}
              className={`w-100 rounded-pill hover-effect-button`}
              onClick={handleBuyBasePackage}
              disabled={loading || isActive || !allowedToBuy}
            >
              {loading 
                ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> 
                : isActive 
                  ? "Active" 
                  : isAuthenticated 
                    ? "Buy Now!" 
                    : "Get Started!"
              }
          </Button>
      </Card.Body>
    </Card>
  );

};

export default BasePackageCard;
