import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Console.styles.css'; // Link to your custom CSS
 
import * as Icons from '../../assets/img/Icons';
const { systemPackage, addButton } = Icons;
 
const AdminConsole: React.FC = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook
 
  // Function to navigate to ManagePackages
  const handleManagePackagesClick = () => {
    navigate('/console/setting/managepackages', { state: { activeTab: 'packages' } });
  };
 
  const handleManageAddOnsClick = () => {
    navigate('/console/setting/managepackages', { state: { activeTab: 'addons' } });
  };
 
  // Function to navigate to CreateAddOns
  const handleCreateAddOnsClick = () => {
    navigate('/console/setting/createaddons');
  };
 
  // Function to navigate to CreateNewPackage
  const handleCreateNewPackageClick = () => {
    navigate('/console/setting/createnewpackage');
  };
 
  return (
    <Container className="mt-5">
      <Row>
        <Col md={6}>
          <h3 className="admin-console">Admin Console</h3>
          <div className="admin-console-buttons d-flex">
            <div className="mx-1">
              {/* Manage Packages button */}
              <Button
                className="btn-purple d-flex align-items-center w-100"
                onClick={handleManagePackagesClick}
              >
                Manage Packages
              </Button>
              <Button className="btn-purple d-flex align-items-center w-100"
              onClick={handleManageAddOnsClick}>
                Manage Add-Ons
              </Button>
            </div>
            <div className="mx-1">
              <Button
                className="btn-blue d-flex align-items-center w-100"
                onClick={handleCreateNewPackageClick}
              >
                <img src={addButton} alt="System Package" />
                Create New Package
              </Button>
              <Button
                className="btn-blue d-flex align-items-center w-100"
                onClick={handleCreateAddOnsClick}
              >
                <img src={addButton} alt="System Package" />
                Create Add-Ons
              </Button>
            </div>
          </div>
        </Col>
        <Col md={6}></Col>
        <div className="system-package-icon">
          <img src={systemPackage} alt="System Package" className="illustration" />
        </div>
      </Row>
    </Container>
  );
};
 
export default AdminConsole;