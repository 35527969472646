
import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import logoStrip from '../../assets/img/Logo-strip.svg';
import heroImage from '../../assets/img/HERO.svg';
import PlayIcon from '../../assets/img/PlayIcon.svg';  // Import the SVG
import './homepage.styles.css'; // Separate styles

const HomePage: React.FC = () => {
  return (
    <Container fluid>
      <header id="header" className="mb-4">
        <Container className="header-content mt-5">
          <Row>
            <Col md={6} lg={5} className="text-left">
              <Image src={logoStrip} alt="Logo Strip" fluid />
              <p className="tagline">
                Simplify Communication: Click, Connect, Resolve.
              </p>
              <p className="description">
                “Effortless connections, infinite conversations! Introducing Click-to-Call – where a single click sparks instant communication. Say goodbye to dialing, and hello to seamless conversations. Click now to make your communication journey smoother than ever!”
              </p>
              <div className="buttons mt-4">
  <Button 
    href="https://www.tetherfi.com" 
    target="_blank" 
    rel="noopener noreferrer"
    className="discover-button"
  >
    Discover
  </Button>
  <div className="how-it-works d-inline-flex ml-4"> 
    <div className="play-circle">
      <Image src={PlayIcon} alt="Play Icon" fluid />
    </div>
    <span className="ml-2">How it works</span>
  </div>
</div>
            </Col>
            <Col md={6} lg={7} className="text-right">
              <Image src={heroImage} alt="Hero Image" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </header>
    </Container>
  );
};

export default HomePage;





