import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={6}></Col>
          <Col md={6}>
            <Row>
              <Col md={4}>
                <h5 className="footer-title">How it works</h5>
                <a href="#" className="footer-link">
                  New Tutorials
                </a>
                <a href="#" className="footer-link">
                  About News
                </a>
                <a href="#" className="footer-link">
                  Register
                </a>
                <a href="#" className="footer-link">
                  Issues Error
                </a>
              </Col>
              <Col md={4}>
                <h5 className="footer-title">Our Services</h5>
                <a href="#" className="footer-link">
                  Available
                </a>
                <a href="#" className="footer-link">
                  24 Hours
                </a>
                <a href="#" className="footer-link">
                  Call
                </a>
                <a href="#" className="footer-link">
                  Upgrade
                </a>
              </Col>
              <Col md={4}>
                <h5 className="footer-title">Social</h5>
                <a href="#" className="footer-link">
                  Facebook
                </a>
                <a href="#" className="footer-link">
                  Instagram
                </a>
                <a href="#" className="footer-link">
                  Telegram
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
